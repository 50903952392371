import {domReady} from '@roots/sage/client';
import { jarallax, jarallaxVideo } from "jarallax";

/**
 * app.main
 */
const main = async (err) => {
    if (err) {
        // handle hmr errors
        console.error(err);
    }

    // Optional video extension
    jarallaxVideo();

    jarallax(document.querySelectorAll('.jarallax'), {
        speed: 0.2,
    });


    // https://github.com/michalsnik/aos#animations
    AOS.init();

       
    // Function to update header height and refresh padding on mobile menu holder
    function updateHeaderHeight() {
        if (jQuery(window).width() < 1199) { // desktop only
            var headerHeight = jQuery('.sticky-header').outerHeight(); // get the header height
            jQuery('#main-menu-modal').css('top', headerHeight); // match the height of the header with the nav
            jQuery('.mobile-menu-holder').css('top', headerHeight); // match the height of the header with the nav
        }

        if (jQuery('.site-header.sticky-header').length) {
            var headerHeight = jQuery('.sticky-header').outerHeight(); // get the header height
            jQuery('#main').css('padding-top', headerHeight); // match the height of the header with the nav
            jQuery('.mobile-menu-holder').css('top', headerHeight); // match the height of the header with the nav
        }
    }

    jQuery(document).ready(function ($) {

        // Initial call to set up header height
        updateHeaderHeight();

        jQuery(window).scroll(function () {
            if (jQuery(window).width() > 1200) { // desktop only

                if (jQuery(document).scrollTop() > 50) { // scroll this amount to trigger
                    jQuery('.site-header.sticky-header').addClass('shrink'); // add shrink when we are at the top
                    setTimeout(function () { // run the same header height matching on scroll
                        updateHeaderHeight(); // call the function to update header height
                    }, 400);
                } else {
                    jQuery('.site-header.sticky-header').removeClass('shrink'); // remove shrink when we are at the top
                }
            }
        });

    });

    jQuery(window).resize(function ($) {
        // Initial call to set up header height
        updateHeaderHeight();

        jQuery(window).scroll(function () {
            var headerHeight = jQuery('.sticky-header').outerHeight(); // get the header height
            jQuery('#main-menu-modal').css('top', headerHeight); // match the height of the header with the nav
            jQuery('.mobile-menu-holder').css('top', headerHeight); // match the height of the header with the nav
        });
    });




    // scroll-up-header
    jQuery(document).ready(function ($) {
        if (jQuery(window).width() < 1199) { // desktop only
            var headerHeight = jQuery('.scroll-up-header').outerHeight(); // get the header height
            jQuery('#main-menu-modal').css('top', headerHeight); // match the height of the header with the nav
            jQuery('.mobile-menu-holder').css('top', headerHeight); // match the height of the header with the nav
        }

        if ($(window).width() > 1200) { // desktop only

            if ($('.site-header.scroll-up-header').length) {
                var prevScrollpos = window.pageYOffset;
                var headerDiv = document.querySelector('.scroll-up-header'); // Get the header element and it's position
                var headerBottom = headerDiv.offsetTop + headerDiv.offsetHeight;
                var headerHeight = $('.scroll-up-header').outerHeight(); // get the header height
                $('#main').css('padding-top', headerHeight); // match the height of the header with the main

                console.log(headerHeight);

                window.onscroll = function () {
                    var currentScrollPos = window.pageYOffset;
                    if (prevScrollpos <= currentScrollPos) {  // if scrolling down, let it scroll out of view as normal
                        headerDiv.classList.remove('fixedToTop');
                        //console.log('header height is' headerHeight);
                        headerDiv.style.top = '-' + headerHeight + 'px';
                    } else {  // otherwise if we're scrolling up, fix the nav to the top
                        headerDiv.classList.add('fixedToTop');
                        headerDiv.style.top = '0';
                        $('#main').css('padding-top', headerHeight); // match the height of the header with the mau
                    }
                    prevScrollpos = currentScrollPos;
                };
            }

        }
    });

    // standard-header
    // jQuery(document).ready(function ($) {
    //     if ($(window).width() < 1199) { // desktop only
    //         //var headerHeight = $('.standard-header').outerHeight(); // get the header height
    //         //$('#main-menu-modal').css('top', headerHeight); // match the height of the header with the mau
    //     }

    //     if ($(window).width() > 1200) { // desktop only

    //         if ($('.site-header.standard-header').length) {
    //             var prevScrollpos = window.pageYOffset;
    //             var headerDiv = document.querySelector('.standard-header'); // Get the header element and it's position
    //             var headerBottom = headerDiv.offsetTop + headerDiv.offsetHeight;
    //             var headerHeight = $('.standard-header').outerHeight(); // get the header height
    //             //$('#main').css('padding-top', headerHeight); // match the height of the header with the main

    //             console.log(headerHeight);
    //         }

    //     }
    // });


    // search panel
    // var searchlink = document.querySelector(".search-link");
    // var backgroundblur = document.querySelector(".background-blur-on");
    // searchlink.addEventListener("click", function() { // On click
    //     ('body').classList.toggle("open"); // Toggle class "is-active"
    // });


    // mobile hamburger
    var hamburger = document.querySelector(".burger");
    hamburger.addEventListener("click", function() { // On click
      hamburger.classList.toggle("open"); // Toggle class "is-active"
    });


    jQuery(document).ready(function ($) {
        // disable search form until user query is added
        $('form.search-form > input').keyup(function () {
            var empty = false;
            $('form.search-form > input').each(function () {
                if ($(this).val() == '') {
                    empty = true;
                }
            });
            if (empty) {
                $('.cs-search').attr('disabled', 'disabled');
            } else {
                $('.cs-search').removeAttr('disabled');
            }
        });
    });


    // lazyload
    // https://github.com/verlok/vanilla-lazyload
    // var lazyLoadInstance = new LazyLoad({
    //     elements_selector: '.lazy',
    //     callback_load: function () {
    //         if (document.querySelector('.aos-init')) {
    //             AOS.refresh();
    //         }
    //     }
    // });


    // function for detecting a change in the page height which is needed for running AOS + Bootstrap tabs if used.
    function onElementHeightChange(elm, callback) {
        var lastHeight = elm.clientHeight;
        var newHeight;
        (function run() {
            newHeight = elm.clientHeight;
            if (lastHeight !== newHeight) callback();
            lastHeight = newHeight;
            if (elm.onElementHeightChangeTimer) {
                clearTimeout(elm.onElementHeightChangeTimer);
            }
            elm.onElementHeightChangeTimer = setTimeout(run, 200); // timeout allows animations to run and clear first
        })();
    }

    // detect a change in the page height and refresh AOS
    onElementHeightChange(document.body, function () {
        AOS.refresh();
    });

};

/**
 * Initialize
 *
 * @see https://webpack.js.org/api/hot-module-replacement
 */
domReady(main);
import.meta.webpackHot?.accept(main);
